.introduction-page {
  text-align: center;
  h1 {
    font-size: map-get($font-size, l);
    @include pxRem(padding, 40 0);
    @include pxRem(margin, 0);
  }
  .cover-img {
    // cover image for <376px screen
    .s-cover {
      display: none;
    }
    // cover image for >=376px screen
    .m-cover {
      display: inline;
    }
  }
  .intro-content {
    @include pxRem(padding, 28 0);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px dashed map-get($color, grey-j);
    .intro-icon {
      @include pxRem(margin-right, 10);
      @include pxRem(max-width, 96);
      width: 30%;

    }
    .intro-text {
      @include pxRem(max-width, 240);
      width: calc(70% - 10px);
      font-weight: 700;
    }
    &:last-child {
      border: none;
    }
    .intro-note {
      @include pxRem(margin-top, 19);
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: left;
    }
    .intro-note-p {
      @include pxRem(margin-top, 19);
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: left;
      flex-direction: column;
    }
  }
  .question-btn {
    @include pxRem(padding, 0 32);
    display: inline-block;
  }
}

@media (max-width: 375px) {
  .introduction-page {
    .cover-img {
      // cover image for <376px screen
      .s-cover {
        display: inline;
      }
      // cover image for >=376px screen
      .m-cover {
        display: none;
      }
    }
    .intro-content {
      flex-direction: column;
      .intro-text {
        @include pxRem(margin-top, 28);
        width: 100%;
        max-width: 100%;

      }
    }
    .question-btn {
      @include pxRem(padding, 0 16);
    }
  }
}
