.page-container {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
}

.dialogRef-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh;
    min-width: 30vw;
    border: none;
}

  dialog::backdrop {
    background-color: #100F0FCC;
  }

.btn {
    border: none;
    color: white;
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;
    box-shadow: none
}

  .closed-btn {
    width: 100%;
    background-color: rgb(225, 229, 232);
    font-size: large;
    font-weight: 900;
    color: black;
    box-shadow: #00000059 5px 5px 12px;
  }

.closed-btn-container {
    width: 90%;
    padding: 10px;
    margin-top: 10%;
}
.point-header {
    width: 351px;
    display: grid;
    padding: 2px;
    font-size: medium;
    font-weight: 900;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 10px;
}

.page-title {
    text-align: center;
}

.point-show {
    text-align: center;
    color: white;
    margin: 30px;
    font-size: 28px;
}

.point-note-container {
    width: 323px;
    padding: 16px;
    background-color: black;
    
    font-weight: 500;
}

.point-note-lebel {
    font-size: 12px;
    align-items: center;
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 12px 12px 14px 14px;
    border-radius: 5px;
    background-color: white;
    color: black;
}

.point-header-level {
    text-align: end;
}

.menu-switch {
    color: red;
    cursor: pointer;
    text-align: end;
}

.point-menu {
    padding: 9px 9px 15px 15px;
    background-color: white;
    margin-top: 3px;   

    p {
        font-size: 14px;
    }

}
.moth-resume{
    padding-top: 10px;
}

.btn-black {
    background-color: black;
    font-size: 20px;
    font-weight: 900;
    margin-top: 3px;
}

.btn-black:hover {
    background-color: #000000CC;
}

.point-resume-container {
    width: 355px;
}

.change-month {

    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
}

.select-month {
    text-align: center;
    font-size: large;
    font-weight: 900;
}

.pre-month {
    cursor: pointer;
    font-size: large;
    font-weight: 500;
}

.next-month {
    cursor: pointer;
    font-size: large;
    font-weight: 500;
    text-align: end;
}

.type-select {
    display: flex;
    justify-content: end;
    padding: 15px;
    border-bottom: 1px solid rgb(169, 161, 161);
}

.select-wrapper {
    box-sizing: border-box;
    position: relative;

}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    appearance: none;
    display: block;
    padding: 8px 25px 8px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.arrow {
    color: #ccc;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    z-index: 2;
    pointer-events: none;
    /* 確保點擊事件可以傳遞到 select */
}
.resume-list{
    h1{
        text-align: center;
        padding: 5px;
    }
}

.resume-item {
    display: grid;
    grid-template-columns: 4fr 3fr;
    padding: 5px;
    border-bottom: 1px solid rgb(169, 161, 161);
}

.resume-type {
    border-radius: 10px;
    background-color: rgb(169, 161, 161);
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 2px;
    padding-bottom: 2px;

}

.resume-point {
    margin-left: 15px;
}

.point {
    color: green;
}

.point_negative {
    color: red;
}

.PageControl{
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .page-btn{
        padding: 2px;
        border: none;
        box-shadow: none;
        cursor: pointer;
    }
    .next_page{
       text-align: right;
    }

}